import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _25171c9a = () => interopDefault(import('..\\pages\\about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _7b4d1af0 = () => interopDefault(import('..\\pages\\category\\index.vue' /* webpackChunkName: "pages/category/index" */))
const _372913d8 = () => interopDefault(import('..\\pages\\contact.vue' /* webpackChunkName: "pages/contact" */))
const _5402e42f = () => interopDefault(import('..\\pages\\cookie-policy.vue' /* webpackChunkName: "pages/cookie-policy" */))
const _0a737b01 = () => interopDefault(import('..\\pages\\find-a-vet\\index.vue' /* webpackChunkName: "pages/find-a-vet/index" */))
const _01f43b95 = () => interopDefault(import('..\\pages\\join-us.vue' /* webpackChunkName: "pages/join-us" */))
const _6f1df160 = () => interopDefault(import('..\\pages\\map.vue' /* webpackChunkName: "pages/map" */))
const _4cd2835c = () => interopDefault(import('..\\pages\\meo-gold.vue' /* webpackChunkName: "pages/meo-gold" */))
const _17530546 = () => interopDefault(import('..\\pages\\mock.vue' /* webpackChunkName: "pages/mock" */))
const _0eb14246 = () => interopDefault(import('..\\pages\\mock-about.vue' /* webpackChunkName: "pages/mock-about" */))
const _67e5a8da = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _16454353 = () => interopDefault(import('..\\pages\\privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _26a36eb7 = () => interopDefault(import('..\\pages\\reward\\index.vue' /* webpackChunkName: "pages/reward/index" */))
const _48b5f8c6 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _5ddb3995 = () => interopDefault(import('..\\pages\\tvc.vue' /* webpackChunkName: "pages/tvc" */))
const _7f239ce2 = () => interopDefault(import('..\\pages\\find-a-vet\\vets.vue' /* webpackChunkName: "pages/find-a-vet/vets" */))
const _74b40314 = () => interopDefault(import('..\\pages\\reward\\reward-detail.vue' /* webpackChunkName: "pages/reward/reward-detail" */))
const _3ea92f92 = () => interopDefault(import('..\\pages\\category\\_id\\index.vue' /* webpackChunkName: "pages/category/_id/index" */))
const _10266567 = () => interopDefault(import('..\\pages\\news\\_id\\index.vue' /* webpackChunkName: "pages/news/_id/index" */))
const _75b89cbb = () => interopDefault(import('..\\pages\\product\\_id\\index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _6468da57 = () => interopDefault(import('..\\pages\\reader\\_id\\index.vue' /* webpackChunkName: "pages/reader/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _25171c9a,
    name: "about-us___en"
  }, {
    path: "/category",
    component: _7b4d1af0,
    name: "category___en"
  }, {
    path: "/contact",
    component: _372913d8,
    name: "contact___en"
  }, {
    path: "/cookie-policy",
    component: _5402e42f,
    name: "cookie-policy___en"
  }, {
    path: "/find-a-vet",
    component: _0a737b01,
    name: "find-a-vet___en"
  }, {
    path: "/join-us",
    component: _01f43b95,
    name: "join-us___en"
  }, {
    path: "/map",
    component: _6f1df160,
    name: "map___en"
  }, {
    path: "/meo-gold",
    component: _4cd2835c,
    name: "meo-gold___en"
  }, {
    path: "/mock",
    component: _17530546,
    name: "mock___en"
  }, {
    path: "/mock-about",
    component: _0eb14246,
    name: "mock-about___en"
  }, {
    path: "/news",
    component: _67e5a8da,
    name: "news___en"
  }, {
    path: "/privacy-policy",
    component: _16454353,
    name: "privacy-policy___en"
  }, {
    path: "/reward",
    component: _26a36eb7,
    name: "reward___en"
  }, {
    path: "/th",
    component: _48b5f8c6,
    name: "index___th"
  }, {
    path: "/tvc",
    component: _5ddb3995,
    name: "tvc___en"
  }, {
    path: "/find-a-vet/vets",
    component: _7f239ce2,
    name: "find-a-vet-vets___en"
  }, {
    path: "/reward/reward-detail",
    component: _74b40314,
    name: "reward-reward-detail___en"
  }, {
    path: "/th/about-us",
    component: _25171c9a,
    name: "about-us___th"
  }, {
    path: "/th/category",
    component: _7b4d1af0,
    name: "category___th"
  }, {
    path: "/th/contact",
    component: _372913d8,
    name: "contact___th"
  }, {
    path: "/th/cookie-policy",
    component: _5402e42f,
    name: "cookie-policy___th"
  }, {
    path: "/th/find-a-vet",
    component: _0a737b01,
    name: "find-a-vet___th"
  }, {
    path: "/th/join-us",
    component: _01f43b95,
    name: "join-us___th"
  }, {
    path: "/th/map",
    component: _6f1df160,
    name: "map___th"
  }, {
    path: "/th/meo-gold",
    component: _4cd2835c,
    name: "meo-gold___th"
  }, {
    path: "/th/mock",
    component: _17530546,
    name: "mock___th"
  }, {
    path: "/th/mock-about",
    component: _0eb14246,
    name: "mock-about___th"
  }, {
    path: "/th/news",
    component: _67e5a8da,
    name: "news___th"
  }, {
    path: "/th/privacy-policy",
    component: _16454353,
    name: "privacy-policy___th"
  }, {
    path: "/th/reward",
    component: _26a36eb7,
    name: "reward___th"
  }, {
    path: "/th/tvc",
    component: _5ddb3995,
    name: "tvc___th"
  }, {
    path: "/th/find-a-vet/vets",
    component: _7f239ce2,
    name: "find-a-vet-vets___th"
  }, {
    path: "/th/reward/reward-detail",
    component: _74b40314,
    name: "reward-reward-detail___th"
  }, {
    path: "/",
    component: _48b5f8c6,
    name: "index___en"
  }, {
    path: "/th/category/:id",
    component: _3ea92f92,
    name: "category-id___th"
  }, {
    path: "/th/news/:id",
    component: _10266567,
    name: "news-id___th"
  }, {
    path: "/th/product/:id",
    component: _75b89cbb,
    name: "product-id___th"
  }, {
    path: "/th/reader/:id",
    component: _6468da57,
    name: "reader-id___th"
  }, {
    path: "/category/:id",
    component: _3ea92f92,
    name: "category-id___en"
  }, {
    path: "/news/:id",
    component: _10266567,
    name: "news-id___en"
  }, {
    path: "/product/:id",
    component: _75b89cbb,
    name: "product-id___en"
  }, {
    path: "/reader/:id",
    component: _6468da57,
    name: "reader-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
